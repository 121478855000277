<template>
  <BaseModal
    name="add-grupo"
    id="add-grupo"
    idModal="add-grupo"
    size="ms"
    :title="editGrupo ? 'Editar grupo' : 'Cadastrar grupo'"
    @shown="openModal"
  >
    <b-row v-if="!loading">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p v-if="editGrupo">Insira os dados para atualizar o grupo.</p>
            <p v-else>Insira os dados para cadastrar um grupo.</p>
          </div>
        </div>
        <b-form data-vv-name="users">
          <b-row>
            <b-col cols="12">
              <b-form-group label="Nome">
                <b-form-input
                  v-model="title"
                  name="users"
                  placeholder="Insira o nome do grupo"
                  v-validate="'required'"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('Usuários')">
                  Por favor, insira os usuários que farão parte do grupo.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12">
              <a href="#" @click="selecionarTodas()" class="btn-todos mr-3"
                >Selecionar Todas</a
              >

              <BaseSelect
                v-if="render"
                :selectModel="usuario"
                placeholder="Insira os usuários que farão parte do grupo"
                label="Usuarios"
                trackname="email"
                track-by="id"
                :array="usuarios"
                specificType="sellers"
                :multiple="true"
                :taggable="true"
                class=""
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                @callback="getVendedor($event)"
                @change="usuario = $event"
                :clear="clear"
                :timeout="500"
                ><span slot="noResult"
                  >Nenhuma usuarios encontrada.</span
                ></BaseSelect
              >
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar grupo
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import GrupoService from "@/services/resources/GrupoService";
const servicegrupos = GrupoService.build();
import Multiselect from "vue-multiselect";

import SellerListService from "@/services/resources/SellerListService";
const serviceSeller = SellerListService.build();

export default {
  components: {
    Multiselect,
  },
  props: {
    editGrupo: {
      require: false,
    },
  },
  data() {
    return {
      edit: false,
      title: "",
      usuario: [],
      usuarios: [],
      loading: false,
      stops: [],
      per_page: [],
      clear: false,
      render: true,
    };
  },
  computed: {
    forms() {
      return this.$store.getters.forms;
    },
  },
  methods: {
    selecionarTodas() {
      this.render = false;
      this.getVendedor(1, 1000, true);
      this.stop = true;
    },

    onSubmit() {
      this.loading = true;

      //validação
      if (this.title.trim() === "" || this.usuario == "") {
        this.$bvToast.toast("Preencha os campos corretamente", {
          title: "Grupos",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        this.loading = false;
        return;
      }

      if (this.edit) {
        let dataEdit = {
          id: this.editGrupo.id,
          blog_id: "",
          title: this.title,
          crated_at: "",
          updated_at: "",
        };
        servicegrupos
          .update(dataEdit)
          .then((resp) => {
            this.$bvToast.toast("Grupo salvo com sucesso", {
              title: "Edição",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit("add-grupo");
            this.$bvModal.hide("add-grupo");
            this.$emit("atualizar");
            // this.$store.dispatch("fetchSellers");
            this.updateUsers(resp.id);
          })
          .catch((err) => {
            // console.log(err);
            this.$bvToast.toast("Erro ao salvar grupo", {
              title: "Edição",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide("add-grupo");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        // console.log("teste");

        let data = {
          id: "",
          title: this.title,
        };
        servicegrupos
          .create(data)
          .then((resp) => {
            this.$bvToast.toast("Grupo salvo com sucesso", {
              title: "Cadastro",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$emit("add-grupo");
            this.$bvModal.hide("add-grupo");
            this.$emit("atualizar");
            // this.$store.dispatch("fetchSellers");
            this.insertUsers(resp.id);
          })
          .catch((err) => {
            // console.log(err);
            this.$bvToast.toast("Erro ao salvar grupo", {
              title: "Cadastro",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.$bvModal.hide("add-grupo");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    insertUsers(id) {
      let data = {
        id: `/users/${id}`,
        title: this.title,
        users: this.usuario.length
          ? this.usuario.map(function (item) {
              return item.id + "";
            })
          : [],
      };

      servicegrupos.createId(data);
    },

    updateUsers(id) {
      let data = {
        id: `/users/${id}`,
        title: this.title,
        users: this.usuario.length
          ? this.usuario.map(function (item) {
              return item.id + "";
            })
          : [],
      };

      servicegrupos.createId(data);
    },
    fetchUsers(id) {
      servicegrupos.read({ id: `/users/${id}` }).then((resp) => {
        var usuarios = this.usuarios;
        for (let i = 0; i < resp.length; i++) {
          const element = resp[i];
          let result = usuarios.find((user) => user.id == element);
          if (result) {
            this.usuario.push(result);
          }
        }
      });
    },
    getVendedor(page = 1, per_page = 20, all = false) {
      if (this.stops.indexOf("seller") != -1 && !all) {
        return;
      }

      var data = {
        page: page,
        per_page: per_page,
      };

      serviceSeller
        .search(data)
        .then((resp) => {
          if (resp.data.length < resp.per_page) {
            this.stops.push("seller");
          }

          if (all) {
            this.clear = true;
            this.usuarios = resp.data;
            this.usuario = resp.data;
            this.render = true;
          } else {
            this.usuarios = this.usuarios.concat(resp.data);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    openModal() {
      this.usuario = [];
      this.title = "";
      if (this.editGrupo) {
        // // console.log("existe -> ", this.editGrupo);
        this.title = this.editGrupo.title;
        this.edit = true;
        this.fetchUsers(this.editGrupo.id);
      } else {
        this.edit = false;
      }
      // console.log(this.edit);
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
</style>
