<template>
  <BaseModal
    name="add-usuario"
    id="add-usuario"
    idModal="add-usuario"
    size="lg"
    :title="editUser ? 'Editar usuário' : 'Cadastrar usuário'"
    @shown="openModal"
  >
    <b-row v-if="!loading" id="ajuste">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p v-if="editUser">Insira os dados para atualizar o usuário.</p>
            <p v-else>Insira os dados para cadastrar um usuário.</p>
          </div>
        </div>
        <b-form data-vv-name="nome">
          <b-row>
            <b-col cols="6">
              <b-form-group label="Nome">
                <b-form-input
                  v-model="nome"
                  name="nome"
                  placeholder="Nome"
                  v-validate="{ min: 3, max: 50 }"
                  :style="{
                    border: errors.has('nome')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="errorMsg"
                  :state="!errors.has('nome')"
                >
                  {{
                    nome.length === 0
                      ? "Por favor, insira o nome."
                      : nome.length < 3 || nome.length > 50
                      ? "Mínimo de 3 caracteres e máximo de 50 caracteres."
                      : ""
                  }}
                </b-form-invalid-feedback>
                <span v-if="validSpaceNome" class="errorMsg">
                  Espaços inválidos.
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Sobrenome">
                <b-form-input
                  v-model="sobrenome"
                  name="sobrenome"
                  placeholder="Sobrenome"
                  v-validate="{ min: 3, max: 50 }"
                  :style="{
                    border: errors.has('sobrenome')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="errorMsg"
                  :state="!errors.has('sobrenome')"
                >
                  {{
                    sobrenome.length === 0
                      ? "Por favor, insira o sobrenome."
                      : sobrenome.length < 3 || sobrenome.length > 50
                      ? "Mínimo de 3 caracteres e máximo de 50 caracteres."
                      : ""
                  }}
                </b-form-invalid-feedback>
                <span v-if="validSpaceSobrenome" class="errorMsg">
                  Espaços inválidos.
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="6">
              <b-form-group label="E-mail">
                <b-form-input
                  type="text"
                  v-model="email"
                  id="email"
                  name="email"
                  placeholder="E-mail"
                  :disabled="edit"
                  :style="{
                    border: errors.has('email')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                ></b-form-input>
                <div style="display: flex; flex-direction: column">
                  <b-form-invalid-feedback
                    class="errorMsg"
                    :state="!errors.has('email')"
                  >
                    Por favor, insira um e-mail.
                  </b-form-invalid-feedback>
                  <span v-if="emailRegexShow && email !== ''" class="errorMsg">
                    Por favor, insira um e-mail válido.
                  </span>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Telefone" label-for="phone">
                <b-form-input
                  id="phone"
                  name="phone"
                  v-model="telefone"
                  type="text"
                  placeholder="Seu telefone"
                  v-validate="{ min: 14, max: 15 }"
                  :style="{
                    border: errors.has('phone')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                  v-mask="['(##) ####-####', '(##) #####-####']"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="errorMsg"
                  :state="!errors.has('phone')"
                >
                  Por favor, insira um telofone válido.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="6" v-if="edit === false">
              <b-form-group label="Senha" label-for="senha">
                <b-form-input
                  id="senha"
                  name="senha"
                  v-model="password"
                  type="text"
                  placeholder="Senha"
                  v-validate="{ min: 8, max: 50 }"
                  :style="{
                    border: errors.has('senha')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="errorMsg"
                  :state="!errors.has('senha')"
                >
                  {{
                    password.length === 0
                      ? "Por favor, insira uma senha."
                      : password.length < 8 || password.length > 50
                      ? "Mínimo de 8 caracteres e máximo de 60 caracteres."
                      : ""
                  }}
                </b-form-invalid-feedback>
                <span v-if="validSpaceSenha" class="errorMsg">
                  Espaços inválidos.
                </span>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                v-if="!edit || (edit && editUser && editUser.level !== 'owner')"
                label="Permissão"
                label-for="permission"
              >
                <multiselect
                  v-model="permission"
                  placeholder="Permissão"
                  label="name"
                  track-by="id"
                  style="margin-bottom: 4px !important"
                  :options="permissions"
                  :multiple="false"
                  :taggable="true"
                  :class="{
                    errorPermission:
                      (!permission ||
                        !permission.name ||
                        permission.name == '') &&
                      hasError,
                  }"
                  :searchable="false"
                  selectLabel=""
                  deselectLabel=""
                  selectedLabel=""
                  ><span slot="noResult"
                    >Nenhuma permissão encontrada.</span
                  ></multiselect
                >
                <span
                  class="errorMsg"
                  v-show="errorPermission"
                  v-if="
                    !permission || !permission.name || permission.name == ''
                  "
                  >Por favor, insira uma permissão.</span
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="6" v-if="edit === false">
              <b-form-group label="CPF" label-for="document">
                <b-form-input
                  id="document"
                  name="document"
                  v-model="document"
                  type="text"
                  placeholder="Documento"
                  min="0"
                  :style="{
                    border: errors.has('document')
                      ? '1px solid var(--red) !important'
                      : '',
                  }"
                  v-mask="['###.###.###-##']"
                ></b-form-input>
                <b-form-invalid-feedback
                  class="errorMsg"
                  :state="!errors.has('document')"
                >
                  Por favor, insira o cpf.
                </b-form-invalid-feedback>
                <span
                  v-if="!isDocumentValid && document.length > 0"
                  class="errorMsg"
                >
                  Por favor, insira um cpf válido.
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-if="(editUser && editUser.level !== 'owner') || !editUser"
            class="mt-3"
            cols="6"
          >
          <BaseSelect
          id="funis"
          class="selectFunil"
          :selectModel="selectedFunnels"
          placeholder="Selecione um funil"
          trackname="title"
          track-by="id"
          :array="campaigns"
          @change="filterFunnels($event)"
          :searchable="true"
          direction="top"
          :multiple="true"
          :hide-selected="true"
          selectLabel=""
          deselectLabel=""
          selectedLabel=""
          @search="debounceCampanhas($event)"
          :loading="loadingCampanhas"
          @callback="fetchCampanhas($event)"
          :watch="true"
          ><span slot="noResult">Nenhum funil encontrado.</span>
        </BaseSelect>
          </div>
        </b-form>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar usuário
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import UsuariosEditService from "@/services/resources/UsuariosService";
const serviceUsuariosEdit = UsuariosEditService.build();
import Multiselect from "vue-multiselect";

import CampaignService from "@/services/resources/CampaignService";
const serviceCampaign = CampaignService.build();

import _ from "lodash";

export default {
  components: {
    Multiselect,
  },
  props: {
    editUser: {
      require: false,
    },
  },
  data() {
    return {
      errorPermission: false,
      hasError: false,
      emailRegexShow: false,
      owner: "Proprietário",
      edit: false,
      nome: "",
      sobrenome: "",
      email: "",
      telefone: "",
      password: "",
      document: "",
      stops: [],
      funnels: [],
      selectedFunnels: [{ id: null, title: "Todos os funis" }],
      permission: { id: "admin", name: "Administrador" },
      permissions: [
        { id: "admin", name: "Administrador" },
        { id: "commercial_leader", name: "Lider comercial" },
        { id: "gerent_seller", name: "Gerente de vendas" },
        { id: "seller", name: "Vendedor" },
        { id: "blogger", name: "Blogger" },
      ],
      loading: false,

      //funnel
      campaigns: [
        { id: null, title: "Todos os funis" }
      ],
      stopCampanhas: "",
      loadingCampanhas: false,
      searchCampanhas: "",
      pageCampanhas: 1,

    };
  },

  computed: {
    forms() {
      return this.$store.getters.forms;
    },
    isDocumentValid() {
      const regex = /^\d{3}\.\d{3}\.\d{3}-\d{2}$/;
      return regex.test(this.document);
    },
    validSpaceNome() {
      const regex = /  /;
      return regex.test(this.nome);
    },
    validSpaceSobrenome() {
      const regex = /  /;
      return regex.test(this.sobrenome);
    },
    validSpaceSenha() {
      const regex = /  /;
      return regex.test(this.password);
    },
  },

  methods: {
    validRegexEmail() {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;
      this.emailRegexShow = !regex.test(this.email);
    },
    onSubmit() {
      if (
        !this.permission ||
        this.permission.name == "" ||
        !this.permission.name
      ) {
        this.errorPermission = true;
        this.hasError = true;
        return;
      } else {
        this.errorPermission = false;
        this.hasError = false;
      }
      this.$validator.validateAll().then((result) => {
        if (!result) {
          return;
        }

        var emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,10}$/g;

        if (this.edit) {
          this.loading = true;
          if (
            this.nome === "" ||
            this.sobrenome === "" ||
            this.validSpaceNome ||
            this.telefone.length < 14 ||
            this.telefone.length > 15 ||
            this.validSpaceSobrenome ||
            this.validSpaceSenha ||
            (this.senha === "" && this.edit === false) ||
            !this.permission.id
          ) {
            this.$bvToast.toast("Todos os campos são obrigatórios", {
              title: "Usuários",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          var data = {
            id: `/distinct-user/${this.editUser.id}`,
            first_name: this.nome,
            last_name: this.sobrenome,
            phone: this.telefone,
            level: this.permission.id,
            document: this.document,
            funnels: this.returnFunnels(),
          };
          if (this.editUser.level === "owner") {
            data.level = "owner";
          }
          serviceUsuariosEdit
            .update(data)
            .then(() => {
              this.$bvToast.toast("Usuário editado com sucesso", {
                title: "Cadastro",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$emit("add-usuario");
              this.$bvModal.hide("add-usuario");
              // this.$store.dispatch("fetchSellers");
            })
            .catch((err) => {
              // console.log(err);
              this.$bvToast.toast("Erro ao salvar usuário", {
                title: "Cadastro",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$bvModal.hide("add-usuario");
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          if (
            this.nome === "" ||
            this.sobrenome === "" ||
            this.email === "" ||
            (this.senha === "" && this.edit === false) ||
            this.telefone.length < 14 ||
            this.telefone.length > 15 ||
            !this.permission.id ||
            this.document === "" ||
            !this.isDocumentValid ||
            this.validSpaceNome ||
            this.validSpaceSobrenome ||
            this.validSpaceSenha ||
            (!this.email.match(emailRegex) && this.email.length > 0)
          ) {
            this.$bvToast.toast("Todos os campos são obrigatórios", {
              title: "Usuários",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }
          this.loading = true;

          let data = {
            id: "/register/private",
            first_name: this.nome,
            last_name: this.sobrenome,
            email: this.email,
            password: this.password,
            password_confirmation: this.password,
            tenant_id: this.$store.getters.user.user.tenant_id,
            phone: this.telefone,
            level: this.permission.id,
            document: this.document,
            funnels: this.returnFunnels(),
          };

          serviceUsuariosEdit
            .createId(data)
            .then((r) => {
              this.$emit("add-usuario");
              this.$bvModal.hide("add-usuario");
              // this.$store.dispatch("fetchSellers");
              if (r && r.success == false) {
                this.$bvToast.toast(
                  "Você atingiu o limite máximo de usuários",
                  {
                    title: "Cadastro",
                    variant: "danger",
                    autoHideDelay: 5000,
                    appendToast: true,
                  }
                );
                return;
              }

              this.$bvModal.hide("add-usuario");
            })

            .catch((err) => {
              this.$bvToast.toast("Erro ao salvar usuário", {
                title: "Cadastro",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$bvModal.hide("add-usuario");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    openModal() {
      if (this.$store.getters.user.user.level === "commercial_leader") {
        this.permissions = [
          { id: "gerent_seller", name: "Gerente de vendas" },
          { id: "seller", name: "Vendedor" },
          { id: "blogger", name: "Blogger" },
        ];
      }
      if (this.editUser === null || this.editUser === undefined) {
        this.edit = false;
        this.nome = "";
        this.sobrenome = "";
        this.email = "";
        this.telefone = "";
        this.permission = "";
        this.password = "";
        this.document = "";
        this.selectedFunnels = [{ id: null, title: "Todos os funis" }];
      } else {
        this.edit = true;
        this.nome = this.editUser.first_name;
        this.sobrenome = this.editUser.last_name;
        this.email = this.editUser.email;
        this.telefone = this.editUser.phone;
        this.document = this.editUser.document;

        if (this.editUser.funnels.length) {
          this.selectedFunnels = this.editUser.funnels;
        }else{
          this.selectedFunnels = [{ id: null, title: "Todos os funis" }];
        }
        if (this.editUser.level == "admin") {
          this.permission = { id: "admin", name: "Administrador" };
          return;
        }
        if (this.editUser.level == "gerent_seller") {
          this.permission = { id: "gerent_seller", name: "Gerente de vendas" };
          return;
        }
        if (this.editUser.level == "seller") {
          this.permission = { id: "seller", name: "Vendedor" };
          return;
        }
        if (this.editUser.level == "blogger") {
          this.permission = { id: "blogger", name: "Blogger" };
          return;
        }
      }
    },
    debounceCampanhas: _.debounce(function (query) {
      if (query === "") {
        this.loadingCampanhas = false;
      } else {
        this.searchCampanhas = query;
        this.fetchCampanhas(this.pageCampanhas, query);
      }
    }, 500),
    fetchCampanhas(page = 1, query = "") {
      if (this.stopCampanhas == true) {
        return;
      }

      serviceCampaign
        .read(`?page=${page}&order_by=name&order=ASC&search=${query}`)
        .then((resp) => {
          this.loadingCampanhas = false;
          if (resp.data.length < resp.per_page && query == "") {
            this.stopCampanhas = true;
          }
          this.campaigns = this.campaigns.concat(resp.data);
          this.campaigns = this.campaigns.filter(
            (item, index, opt) =>
              index === opt.findIndex((t) => t.id === item.id)
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingCampanhas = false;
        });
    },
    filterFunnels(funnels) {
      let allfunnels = funnels.find(funnel => funnel.id === null);
      if(allfunnels){
        this.selectedFunnels = [allfunnels]
        return;
      }
      this.selectedFunnels = funnels;
    },
    returnFunnels() {
      let ids = [];
      let has_all = false;
      this.selectedFunnels.forEach((funnel) => {
        if (!funnel.id) {
          has_all = true;
          return;
        }
        ids.push(funnel.id);
      });

      ids = has_all ? "all" : ids;

      return ids;
    },
  },

  watch: {
    email: "validRegexEmail",
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.link-documentacao {
  padding: 0 25px !important;
  height: 40px !important;
  font-size: 12px;
}
.top-dados {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .top-dados {
    display: block;
  }
  .top-dados #limpar {
    margin-top: 15px;
  }
}
.form-relative {
  position: relative;
}
.btn-todos {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  color: #81858e;
}
.errorMsg {
  font-size: 12px;
  font-weight: 500;
  color: #dc3545 !important;
}
.errorPermission {
  border: 1px solid var(--red);
  border-radius: 10px;
}

#ajuste {
  height: 50% !important;
}
</style>
